<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Nueva promoción</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Type of promotion-->
        <div>
          <label class="vs-select--label mt-5" for="typeOfPromotion">Tipo de promoción</label>
          <v-select id="typeOfPromotion" label="name" :options="typesOfPromotions"
                    name='typesOfPromotions'
                    v-model="promotion.type"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('typesOfPromotions')">{{ errors.first('typesOfPromotions') }}</span>
        </div>
        <!--End-->

        <!--Target-->
        <div v-if="promotion.type.value!=='free'" class="mt-5">
          <h6>¿Será aplicado a categoría o producto?</h6>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="isPack" v-model="promotion.target" vs-value="product">Producto</vs-radio>
            </li>
            <li>
              <vs-radio vs-name="isPack" v-model="promotion.target" vs-value="category">Categoria
              </vs-radio>
            </li>
          </ul>
        </div>
        <!--End-->

        <!-- Name -->
        <vs-input @blur="promotion.name= trimString(promotion.name)" label="Nombre" v-model="promotion.name"
                  class="w-full mt-5" name="name"
                  v-validate="'required|min:1|max:30'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Description -->
        <vs-input @blur="promotion.description= trimString(promotion.description)" label="Descripción"
                  v-model="promotion.description"
                  class="w-full  mt-5" name="description"
                  v-validate="'required|min:1|max:100'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('description')">{{ errors.first('description') }}</span>

        <!-- Start  date-->
        <div class="mt-5">
          <label class="vs-select--label" for="startDate">Fecha de inicio</label>
          <datepicker label="Fecha de inicio" v-model="promotion.startDate" id="startDate"
                      v-validate="'required'" name="startDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('startDate')">{{ errors.first('startDate') }}</span>
        </div>

        <!-- End date-->
        <div class="mt-5">
          <label class="vs-select--label" for="endDate">Fecha de fin</label>
          <datepicker label="Fecha de fin" v-model="promotion.endDate" id="endDate"
                      v-validate="'required'" name="endDate"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('endDate')">{{ errors.first('endDate') }}</span>
        </div>

        <!--Quantity-->
        <vs-input-number class="mt-5 mb-5" label="Cantidad:" v-model.number="promotion.quantity"
                         name="quantity" v-validate="'required|min_value:0'" min="0"/>
        <span class="text-danger text-sm"
              v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
        <!--End-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import vSelect from 'vue-select'

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
export default {
  name: 'NewPromotion',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data() {
    return {
      promotion: {
        quantity: 0,
        target: 'product',
        type: {}
      },
      typesOfPromotions: [
        {name: 'Producto gratis por volumen', value: 'volume'},
        {name: 'Porcentaje de descuento', value: 'percentage'}
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      this.initValues()
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          const obj = {
            ...this.promotion,
            used: 0,
            state: false,
            uid: firebase.auth().currentUser.uid,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }
          obj.startDate.setHours(0, 0, 0)
          obj.endDate.setHours(0, 0, 0)
          // Save in firebase
          db.collection('promotions').add({
            ...obj
          }).then((docRef) => {
            obj.id = docRef.id
            this.$store.dispatch('promotions/addPromotion', {promotion: obj})
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Promoción',
              text: 'Promoción creada correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    initValues() {
      this.promotion = {
        quantity: 0,
        target: 'product',
        type: {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
